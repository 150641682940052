/*
 *   File : listing.js
 *   Author : https://evoqins.com
 *   Description : Screen to show the created, published and removed resources in the MintIT platform.
 *   Version : 1.0.0
*/

import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { CustomTitle } from "../../../Components/Title";
import { DataTableContainer } from "../../../Components/DataTable";
import { CustomTabBar } from "../../../Components/Tab";
import { LinkButton, PrimaryButton, TextButton } from "../../../Components/Buttons";
import { Badge } from "../../../Components/Badge";
import { Modal } from "bootstrap";
import { EmptyScreen } from "../../../Components/Others";
import { DeleteConfirmationModal } from "../../../Components/Modal";

import Colors from "../../../Styles/color.module.scss";

import Config from "../../../Helper/config";
import APIService from "../../../Services/api-service";

const TAB_DATA = [{
    id: 1,
    label: "Published"
},
{
    id: 2,
    label: "Scheduled"
},
{
    id: 3,
    label: "Drafts"
}]

// Table custom style
const TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
        },
    },

    headRow: {
        style: {
            borderBottomColor: 'transparent',
            paddingLeft: "32px",
            color: Colors.dark_gray,
            fontFamily: "Montserrat-Bold",
            fontSize: "14px",
            lineHeight: "24px",
            paddingTop: "0px",
            paddingBottom: '0px',

        }
    },
    rows: {
        style: {
            paddingLeft: "32px",
            borderBottom: 'none !important',
            '&:nth-child(odd)': {
                backgroundColor: '#0D0D0D05',
            },

        },
    },
    cells: {
        style: {
            paddingTop: '16px',
            paddingBottom: '16px',
            color: Colors.dark_charcoal,
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "24px",
            paddingLeft: "0px",
            userSelect: "none",
        }
    },
    headCells: {
        style: {
            paddingLeft: "0px"
        }
    },
    pagination: {
        style: {
            paddingRight: "50px",
            paddingTop: "10px",
            borderTop: "none"
        }
    }
};

const ListResources = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [tabIndex, setTabIndex] = useState(1);
    const [openDeleteModal, setOpenDeleteModal] = useState(null);
    const [publishedPageNumber, setPublishedPageNumber] = useState(1);
    const [scheduledPageNumber, setScheduledPageNumber] = useState(1);
    const [draftsPageNumber, setDraftsPageNumber] = useState(1);
    const [screenLoader, setScreenLoader] = useState(true);
    const [paginationData, setPaginationData] = useState({});
    const [publishedList, setPublishedList] = useState([]);
    const [scheduledList, setScheduledList] = useState([]);
    const [draftsList, setDraftsList] = useState([]);
    const [apiLoader, setApiLoader] = useState(false);

    // Resource columns
    const RESOURCES_COLUMN = [
        {
            name: "Title ",
            selector: row => row.title,
            width: '320px',
            cell: row => <div className="d-flex align-items-center gap-16px">
                <div className="border-radius-4px">
                    <img src={row.thumbnail_url}
                        alt={row.title}
                        width={56}
                        height={48} />
                </div>
                <p className="e-montserrat-medium e-font-14 e-line-height-24px mb-0 color-dark-charcoal">{row.title}</p>
            </div>
        },
        {
            name: "Category",
            selector: row => row.category,
            width: '150px'
        },
        {
            name: "Content type",
            cell: row => row.resource_type,
            width: "150px"
        },
        {
            name: "Date created",
            selector: row => row.created_on,
            width: "130px"
        },
        {
            name: "Published date",
            selector: row => row.published_on,
            width: "130px"
        },
        {
            name: "Status",
            selector: row => <Badge status={row.status_text} color={row.status_text === "Active" ? Config.BADGE_COLORS.green :
                row.status_text === "Saved" ? Config.BADGE_COLORS.orange : Config.BADGE_COLORS.blue
            } />,
        },
        {
            name: '',
            sortable: false,
            width: "150px",
            cell: row => <div className="d-flex gap-16px">
                <TextButton
                    className="e-font-14 e-line-height-24" name="Edit" hide_arrow={true}
                    onPress={() => _handleEdit(row)} />
                <TextButton
                    className="e-font-14 e-line-height-24" name="Delete" hide_arrow={true}
                    onPress={() => _openDeleteModal(row)} />
            </div>
        }]

    // Scheduled columns
    const SCHEDULED_COLUMN = [
        {
            name: "Title ",
            selector: row => row.title,
            width: '320px',
            cell: row => <div className="d-flex align-items-center gap-16px">
                <div className="border-radius-4px">
                    <img src={row.thumbnail_url}
                        alt={row.title}
                        width={56}
                        height={48} />
                </div>
                <p className="e-montserrat-medium e-font-14 e-line-height-24px mb-0 color-dark-charcoal">{row.title}</p>
            </div>
        },
        {
            name: "Category",
            selector: row => row.category,
            width: '150px'
        },
        {
            name: "Content type",
            cell: row => row.resource_type,
            width: "130px"
        },
        {
            name: "Date created",
            selector: row => row.created_on,
            width: "130px"
        },
        {
            name: "Scheduled date",
            selector: row => row.scheduled_on,
            width: "150px"
        },
        {
            name: "Status",
            selector: row => <Badge status={row.status_text} color={row.status_text === "Active" ? Config.BADGE_COLORS.green :
                row.status_text === "Saved" ? Config.BADGE_COLORS.orange : Config.BADGE_COLORS.blue
            } />,
        },
        {
            name: '',
            sortable: false,
            width: "150px",
            cell: row => <div className="d-flex gap-16px">
                <TextButton
                    className="e-font-14 e-line-height-24" name="Edit" hide_arrow={true}
                    onPress={() => _handleEdit(row)} />
                <TextButton
                    className="e-font-14 e-line-height-24" name="Delete" hide_arrow={true}
                    onPress={() => _openDeleteModal(row)} />
            </div>
        }]

    // DRAFTS columns
    const DRAFTS_COLUMN = [
        {
            name: "Title ",
            selector: row => row.title,
            width: '320px',
            cell: row => <div className="d-flex align-items-center gap-16px">
                <div className="border-radius-4px">
                    <img src={row.thumbnail_url}
                        alt={row.title}
                        width={56}
                        height={48} />
                </div>
                <p className="e-montserrat-medium e-font-14 e-line-height-24px mb-0 color-dark-charcoal">{row.title}</p>
            </div>
        },
        {
            name: "Category",
            selector: row => row.category,
            width: '150px'
        },
        {
            name: "Date created",
            selector: row => row.created_on,
            width: "130px"
        },
        {
            name: "Content type",
            cell: row => row.resource_type,
            width: "150px"
        },
        {
            name: "Status",
            selector: row => <Badge status={row.status_text} color={row.status_text === "Active" ? Config.BADGE_COLORS.green :
                row.status_text === "Saved" ? Config.BADGE_COLORS.orange : Config.BADGE_COLORS.blue
            } />,
        },
        {
            name: '',
            sortable: false,
            width: "150px",
            cell: row => <div className="d-flex gap-16px">
                <TextButton
                    className="e-font-14 e-line-height-24" name="Edit" hide_arrow={true}
                    onPress={() => _handleEdit(row)} />
                <TextButton
                    className="e-font-14 e-line-height-24" name="Delete" hide_arrow={true}
                    onPress={() => _openDeleteModal(row)} />
            </div>
        }]

    useEffect(() => {
        _getResourceList();
        // eslint-disable-next-line
    }, [publishedPageNumber, tabIndex]);

    useEffect(() => {
        if (location.state !== null) {
            setTabIndex(location.state.tab_index);
        }
    }, [location.state])

    useEffect(() => {
        switch (tabIndex) {
            case 1:
                setPublishedPageNumber(1)
                break;
            case 2:
                setScheduledPageNumber(1)
                break;
            case 3:
                setDraftsPageNumber(1)
                break;


            default:
                break;
        }
    }, [tabIndex])

    useEffect(() => {
        if (openDeleteModal !== null) {
            const modal = new Modal(document.getElementById("delete-modal"), {});
            modal.show();
        }
    }, [openDeleteModal]);

    function _handleTab(tab_index) {
        setTabIndex(tab_index);
        navigate("/manage-resource", {
            state: {
                tab_index: tab_index
            }
        }, {
            replace: true
        })
        setScreenLoader(true);
    }

    function _handleResource() {
        navigate("resource-category")
    }

    function _handleAddResource() {
        navigate("add-resource")
    }

    function _handleEdit(row) {
        navigate("add-resource", {
            state: {
                id: row.resource_id,
            }
        });
    }

    function _openDeleteModal(obj) {
        setOpenDeleteModal(obj);
    }

    function _handleDelete() {
        const resource_list = tabIndex === 1 ? [...publishedList] : tabIndex === 2 ? [...scheduledList] : [...draftsList]
        const index = resource_list.findIndex(item => item.resource_id === openDeleteModal.resource_id);
        resource_list.splice(index, 1)
        if (tabIndex === 1) {
            setPublishedList(resource_list)
        } else if (tabIndex === 2) {
            setScheduledList(resource_list)
        } else {
            setDraftsList(resource_list)
        }
        setOpenDeleteModal(null);
    }

    async function _deleteResource() {
        let status = false
        setApiLoader(true);
        const url = "/resource/delete"
        const request = {
            resource_id: openDeleteModal.resource_id
        }
        await APIService(true, url, request).then((response) => {
            toast.dismiss()
            if (response.status_code === 200) {
                toast.success("Resource deleted", {
                    type: 'success'
                })
                _handleDelete()
                status = true;

            } else {
                status = false;
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setApiLoader(false);
        });
        return status
    }

    function _handleClose() {
        setOpenDeleteModal(null);
    }

    const _handlePageChange = (page) => {
        switch (tabIndex) {
            case 1:
                setPublishedPageNumber(page)
                break;
            case 2:
                setScheduledPageNumber(page)
                break;
            case 3:
                setDraftsPageNumber(page)
                break;


            default:
                break;
        }
        setScreenLoader(true);
    }

    // API - get resource list
    const _getResourceList = () => {
        const url = "/resource/list";
        const request = {
            page_num: tabIndex === 1 ? publishedPageNumber : tabIndex === 2 ? scheduledPageNumber : draftsPageNumber,
            page_size: 12,
            type: tabIndex
        }
        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                if (tabIndex === 1) {
                    setPublishedList(response.data.data);
                }
                else if (tabIndex === 2) {
                    setScheduledList(response.data.data);
                }
                else {
                    setDraftsList(response.data.data);
                }
                setPaginationData({
                    total_count: response.data.total_count,
                    total_page: response.data.total_page,
                    page_size: response.data.page_size,
                    current_page: response.data.current_page,
                    record: response.data.record,
                })
                setScreenLoader(false);
            }
            else {
                setPublishedList([]);
                setScheduledList([]);
                setDraftsList([]);
                setPaginationData({
                    total_count: 0,
                    total_page: 0,
                    page_size: 12,
                    current_page: 1,
                });
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
        })
    }

    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <CustomTitle title="Manage Resources" type={1} />
            </div>
            <div className="e-body-wrapper">

                <div className="row">
                    <div className="col-12">
                        <div className="e-bg-white border-radius-32px padding-40px-tb">
                            <div className="d-flex align-items-center justify-content-between padding-32px-lr">
                                <CustomTabBar data={TAB_DATA}
                                    selectedTab={tabIndex}
                                    onSelectTab={_handleTab} />
                                <div className="d-flex align-items-center gap-32px">
                                    <LinkButton text="Resource category"
                                        handleClick={_handleResource} />
                                    <PrimaryButton label="Add resource"
                                        className="padding-10px-tb padding-38px-lr"
                                        handleClick={_handleAddResource} />
                                </div>
                            </div>
                            {
                                screenLoader === true ?
                                    <div className="h-80vh d-flex align-items-center justify-content-center">
                                        <img src={require("../../../Assets/Images/loader/page-loader.gif")}
                                            alt={"loader"}
                                            width={60}
                                            height={60}
                                        />
                                    </div>
                                    :
                                    <Fragment>
                                        <p className="e-montserrat-semi-bold e-font-16 e-line-height-20 color-dark-charcoal margin-32px-top mb-4 padding-32px-left">{tabIndex === 1 ? "Published" : tabIndex === 2 ? "Scheduled" : "Draft"} resources</p>

                                        <div className="position-relative">
                                            {
                                                tabIndex === 1 ?
                                                    publishedList.length === 0 ?
                                                        <EmptyScreen className="my-5"
                                                            title="No resources found"
                                                            image={require("../../../Assets/Images/loader/empty-funds.png")}
                                                            description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                                                        :
                                                        <Fragment>
                                                            <DataTableContainer data={publishedList}
                                                                columns={RESOURCES_COLUMN}
                                                                customStyles={TABLE_STYLE}
                                                                selectableRows={false}
                                                                paginationComponentOptions={{ noRowsPerPage: true }}
                                                                pagination={true}
                                                                paginationServer={true}
                                                                perPage={12}
                                                                onChangePage={_handlePageChange}
                                                                paginationTotalRows={paginationData.total_count}
                                                                paginationDefaultPage={paginationData.current_page} />
                                                            <span className="e-montserrat-regular e-font-14 e-line-height-18 padding-24px-lr position-absolute color-jett-black bottom-10px">Showing {paginationData.record} of {paginationData.total_count} entries</span>
                                                        </Fragment>

                                                    :
                                                    tabIndex === 2 ?
                                                        scheduledList.length === 0 ?
                                                            <EmptyScreen className="my-5"
                                                                title="No resources found"
                                                                image={require("../../../Assets/Images/loader/empty-funds.png")}
                                                                description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                                                            :
                                                            <Fragment>
                                                                <DataTableContainer data={scheduledList}
                                                                    columns={SCHEDULED_COLUMN}
                                                                    customStyles={TABLE_STYLE}
                                                                    selectableRows={false}
                                                                    paginationComponentOptions={{ noRowsPerPage: true }}
                                                                    defaultSortFieldId={1}
                                                                    pagination={true}
                                                                    paginationServer={true}
                                                                    perPage={12}
                                                                    onChangePage={_handlePageChange}
                                                                    paginationTotalRows={paginationData.total_count}
                                                                    paginationDefaultPage={paginationData.current_page} />
                                                                <span className="e-montserrat-regular e-font-14 e-line-height-18 padding-24px-lr position-absolute color-jett-black bottom-10px">Showing {paginationData.record} of {paginationData.total_count} entries</span>
                                                            </Fragment>

                                                        :
                                                        draftsList.length === 0 ?
                                                            <EmptyScreen className="my-5"
                                                                title="No resources found"
                                                                image={require("../../../Assets/Images/loader/empty-funds.png")}
                                                                description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                                                            :
                                                            <Fragment>
                                                                <DataTableContainer data={draftsList}
                                                                    columns={DRAFTS_COLUMN}
                                                                    customStyles={TABLE_STYLE}
                                                                    selectableRows={false}
                                                                    paginationComponentOptions={{ noRowsPerPage: true }}
                                                                    pagination={true}
                                                                    paginationServer={true}
                                                                    perPage={12}
                                                                    onChangePage={_handlePageChange}
                                                                    paginationTotalRows={paginationData.total_count}
                                                                    paginationDefaultPage={paginationData.current_page} />
                                                                <span className="e-montserrat-regular e-font-14 e-line-height-18 padding-24px-lr position-absolute color-jett-black bottom-10px">Showing {paginationData.record} of {paginationData.total_count} entries</span>
                                                            </Fragment>
                                            }
                                        </div>

                                    </Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                openDeleteModal !== null ?
                    <DeleteConfirmationModal title="Delete resource "
                        description={`Are you sure you want to delete the resource ‘${openDeleteModal.title}’ ? This action cannot be undone.`}
                        delete={_deleteResource}
                        loader={apiLoader}
                        close={_handleClose}
                        updateList={_handleClose} />
                    :
                    null
            }
        </Fragment>
    )
}

export default ListResources;